import "./index.css";

const Hero = () => {
  return (
    <section className="hero">
      <img src="../../../assets/images/hero.webp" />
    </section>
  );
};
export default Hero;
