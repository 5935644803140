import { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const [selectedOption, setSelectedOption] = useState(currentLang);

  const handleOptionClick = (option, lng) => {
    setSelectedOption(option);
    i18n.changeLanguage(lng);
  };

  const getSliderPosition = () => {
    switch (selectedOption) {
      case "ru":
        return 0; // первая позиция
      case "en":
        return 33; // вторая позиция
      case "ro":
        return 66; // третья позиция
      default:
        return 0; // позиция по умолчанию
    }
  };

  return (
    <nav className="toggle-container" aria-label="Toggle Switch">
      <ul className="toggle-switch">
        <li className="toggle-option">
          <button
            className={selectedOption === "ru" ? "active" : ""}
            onClick={() => handleOptionClick("ru", "ru")}
            aria-pressed={selectedOption === "ru"}
          >
            Ru
          </button>
        </li>
        <li className="toggle-option">
          <button
            className={selectedOption === "en" ? "active" : ""}
            onClick={() => handleOptionClick("en", "en")}
            aria-pressed={selectedOption === "en"}
          >
            En
          </button>
        </li>
        <li className="toggle-option">
          <button
            className={selectedOption === "ro" ? "active" : ""}
            onClick={() => handleOptionClick("ro", "ro")}
            aria-pressed={selectedOption === "ro"}
          >
            Ro
          </button>
        </li>
        <div
          className="toggle-slider"
          style={{ left: `${getSliderPosition()}%` }}
        ></div>
      </ul>
    </nav>
  );
};

export default LanguageSwitcher;
