import {
  casesImg,
  folderAnimationVideo,
  folderImg,
  reviewsImg,
} from "../../utils";
import DownloadButton from "../../ui/download-btn";
import "./index.css";
import { useTranslation } from "react-i18next";

const GridFolders = () => {
  const { t } = useTranslation();
  return (
    <div className="grid-container">
      <a href="#about-us" className="link-folder aboutUs">
        <div className="row-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <div className="center-elements">
          <img src={folderImg} className="folderImg" />
          <p>{t("aboutUs")}</p>
        </div>
      </a>

      <div className="cases-wrapper">
        <div className="cases">
          <img src={casesImg} />
          <p>{t("cases")}</p>
        </div>

        <div className="dwnl-btn">
          <DownloadButton />
        </div>
      </div>
      <img src={folderAnimationVideo} className="anim-cases" />

      <div className="reviews">
        <a href="#reviews" className="link-folder reviews">
          <div className="row-dots">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <img src={reviewsImg} />
          <p>{t("reviews")}</p>
        </a>
      </div>
    </div>
  );
};

export default GridFolders;
