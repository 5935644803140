import { useTranslation } from "react-i18next";
import "./index.css";

export const Gallery = () => {
  const { t } = useTranslation();
  return (
    <div className="container-wrapper">
      <p>{t("OurMerch")}</p>
      <div className="container-gallery">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
