import { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const splitTextToLetters = (text) => {
  return text.split("").map((char, index) => (
    <span key={index} className="letter">
      {char}
    </span>
  ));
};
/* eslint-disable */
const Heading = ({ title, text }) => {
  const headingTitleRef = useRef(null);
  const headingTextRef = useRef(null);

  useEffect(() => {
    const titleLetters = headingTitleRef.current.querySelectorAll(".letter");
    const textLetters = headingTextRef.current.querySelectorAll(".letter");

    gsap.fromTo(
      titleLetters,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: "power3.out",
        stagger: 0.03,
        scrollTrigger: {
          trigger: headingTitleRef.current,
          start: "top 80%",
          end: "bottom 20%",
        },
      }
    );

    gsap.fromTo(
      textLetters,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: "power3.out",
        stagger: 0.04,
        scrollTrigger: {
          trigger: headingTextRef.current,
          start: "top 80%",
          end: "bottom 20%",
        },
      }
    );
  }, []);

  return (
    <div className="heading">
      <div className="heading-title" ref={headingTitleRef}>
        {splitTextToLetters(title)}
      </div>
      <div className="heading-text" ref={headingTextRef}>
        {splitTextToLetters(text)}
      </div>
    </div>
  );
};

export default Heading;
