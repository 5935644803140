import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

// import hmv from "/assets/videos/hero.mp4";
// import smallmv from "/assets/videos/hero.webm";
import folderAnimation from "/assets/images/folder-animation.apng";

import apple from "/assets/images/apple.svg";
import search from "/assets/images/search.svg";
import bag from "/assets/images/bag.svg";
import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";
import logo from "/assets/images/logo.svg";
import downloadBtn from "/assets/images/download-btn.svg";
import folder from "/assets/images/folder.svg";
import leftArrow from "/assets/images/left-arrow.svg";
import rightArrow from "/assets/images/right-arrow.svg";

import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.jpg";
import chip from "/assets/images/chip.jpeg";
import frame from "/assets/images/frame.png";
import aboutUs from "/assets/images/about_us.png";
import cases from "/assets/images/cases.png";
import reviews from "/assets/images/reviews.png";
import animCases from "/assets/images/anim_cases.png";

// export const heroVideoMp4 = hmv;
// export const heroVideoWebm = smallmv;
export const folderAnimationVideo = folderAnimation;

export const appleImg = apple;
export const searchImg = search;
export const bagImg = bag;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;
export const logoImg = logo;
export const downloadBtnImg = downloadBtn;
export const aboutUsImg = aboutUs;
export const casesImg = cases;
export const reviewsImg = reviews;
export const animCasesImg = animCases;
export const folderImg = folder;
export const leftArrowImg = leftArrow;
export const rightArrowImg = rightArrow;

export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const chipImg = chip;
export const frameImg = frame;
