import Marquee from "react-fast-marquee";
import "./index.css";
import { useTranslation } from "react-i18next";

const CarouselText = () => {
  const { t } = useTranslation();

  return (
    <div className="text-carousel-wrapper">
      <Marquee direction="right" pauseOnHover>
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>{" "}
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>
      </Marquee>
      <Marquee direction="left" pauseOnHover>
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>
      </Marquee>
      <Marquee direction="right" pauseOnHover>
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>{" "}
        <div className="text-carousel">
          <svg
            className="arrow-rounded"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="40"
              cy="40"
              r="37.2414"
              transform="rotate(-90 40 40)"
              stroke="#2471FC"
              strokeWidth="5.51724"
            />
            <path
              d="M62.425 40.4716L64.2758 38.426L60.1845 34.7244L58.3337 36.77L62.425 40.4716ZM44.9537 59.7819L62.425 40.4716L58.3337 36.77L40.8625 56.0804L44.9537 59.7819Z"
              fill="#2471FC"
            />
            <path
              d="M40.8625 22.0808L39.0117 20.0352L43.1029 16.3336L44.9537 18.3792L40.8625 22.0808ZM44.9537 18.3792L62.425 37.6895L58.3337 41.3911L40.8625 22.0808L44.9537 18.3792Z"
              fill="#2471FC"
            />
            <path
              d="M19 39.5405L58.5404 39.5405"
              stroke="#2471FC"
              strokeWidth="5.51724"
              strokeMiterlimit="16"
            />
          </svg>
          <p>{t("LetsDo")}</p>
        </div>
      </Marquee>
    </div>
  );
};
export default CarouselText;
