import Marquee from "react-fast-marquee";
import "./index.css";
import { useTranslation } from "react-i18next";

const CarouselCompany = () => {
  const { t } = useTranslation();

  return (
    <div id="clients">
      <p className="title-logos">{t("Clients")}</p>
      <div className="logos">
        <Marquee direction="right" pauseOnHover>
          <img
            className="company-logo"
            src="../../../assets/images/haier.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/candy.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/thunderobot.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/yandex.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/perekrestok.png"
          />
        </Marquee>

        <Marquee direction="left" pauseOnHover>
          <img
            className="company-logo"
            src="../../../assets/images/thunderobot.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/haier.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/perekrestok.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/candy.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/yandex.png"
          />
        </Marquee>

        <Marquee direction="right" pauseOnHover>
          <img
            className="company-logo"
            src="../../../assets/images/haier.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/yandex.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/candy.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/perekrestok.png"
          />
          <img
            className="company-logo"
            src="../../../assets/images/thunderobot.png"
          />
        </Marquee>
      </div>
    </div>
  );
};

export default CarouselCompany;
